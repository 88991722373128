import EnvFile from "./assets/env-stg.json";

declare global {
  interface Window {
    MLBBest: {
      envVariables: any;
    };
  }
}

window.MLBBest = {
  envVariables: EnvFile,
};
